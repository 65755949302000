import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Client-side load balancing and service discovery";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const Warning = makeShortcode("Warning");
const RequiredDependencies = makeShortcode("RequiredDependencies");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "client-side-load-balancing-and-service-discovery",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#client-side-load-balancing-and-service-discovery",
        "aria-label": "client side load balancing and service discovery permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Client-side load balancing and service discovery`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#creating-an-endpointgroup"
        }}>{`Creating an EndpointGroup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#choosing-an-endpointselectionstrategy"
        }}>{`Choosing an EndpointSelectionStrategy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#connecting-to-an-endpointgroup"
        }}>{`Connecting to an EndpointGroup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#cleaning-up-an-endpointgroup"
        }}>{`Cleaning up an EndpointGroup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#removing-unhealthy-endpoint-with-healthcheckedendpointgroup"
        }}>{`Removing unhealthy Endpoint with HealthCheckedEndpointGroup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#dns-based-service-discovery-with-dnsendpointgroup"
        }}>{`DNS-based service discovery with DnsEndpointGroup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#zookeeper-based-service-discovery-with-zookeeperendpointgroup"
        }}>{`ZooKeeper-based service discovery with ZooKeeperEndpointGroup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#eureka-based-service-discovery-with-eurekaendpointgroup"
        }}>{`Eureka-based service discovery with EurekaEndpointGroup`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#consul-based-service-discovery-with-consulendpointgroup"
        }}>{`Consul-based service discovery with ConsulEndpointGroup`}</a></li>
    </ul>
    <p>{`You can configure an Armeria client to distribute its requests to more than one server autonomously, unlike
traditional server-side load balancing where the requests go through a dedicated load balancer such as
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Multilayer_switch#Layer_4%E2%80%937_switch,_web_switch,_or_content_switch"
      }}>{`L4 and L7 switches`}</a>{`.`}</p>
    <p>{`There are 3 elements involved in client-side load balancing in Armeria:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`type://Endpoint`}</a>{` represents an individual host (with an optional port number) and its weight.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
        }}>{`type://EndpointGroup`}</a>{` represents a set of `}<a parentName="li" {...{
          "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`typeplural://Endpoint`}</a>{`.`}</li>
      <li parentName="ul">{`A user specifies an `}<a parentName="li" {...{
          "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
        }}>{`type://EndpointGroup`}</a>{` when building a client.`}</li>
    </ul>
    <h2 {...{
      "id": "creating-an-endpointgroup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#creating-an-endpointgroup",
        "aria-label": "creating an endpointgroup permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating an `}<inlineCode parentName="h2">{`EndpointGroup`}</inlineCode></h2>
    <p>{`There are various `}<a parentName="p" {...{
        "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
      }}>{`type://EndpointGroup`}</a>{` implementations provided out of the box, but let's start simple with
`}<a parentName="p" {...{
        "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
      }}>{`type://EndpointGroup`}</a>{` which always yields a pre-defined set of `}<a parentName="p" {...{
        "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
      }}>{`typeplural://Endpoint`}</a>{` specified at construction
time:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.Endpoint;
import com.linecorp.armeria.client.endpoint.EndpointGroup;

// Create a group of well-known search engine endpoints.
EndpointGroup searchEngineGroup = EndpointGroup.of(
        Endpoint.of("www.google.com", 443),
        Endpoint.of("www.bing.com", 443),
        Endpoint.of("duckduckgo.com", 443));

List<Endpoint> endpoints = searchEngineGroup.endpoints();
assert endpoints.contains(Endpoint.of("www.google.com", 443));
assert endpoints.contains(Endpoint.of("www.bing.com", 443));
assert endpoints.contains(Endpoint.of("duckduckgo.com", 443));
`}</code></pre>
    <h2 {...{
      "id": "choosing-an-endpointselectionstrategy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#choosing-an-endpointselectionstrategy",
        "aria-label": "choosing an endpointselectionstrategy permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Choosing an `}<inlineCode parentName="h2">{`EndpointSelectionStrategy`}</inlineCode></h2>
    <p>{`An `}<a parentName="p" {...{
        "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
      }}>{`type://EndpointGroup`}</a>{` is created with `}<inlineCode parentName="p">{`EndpointSelectionStrategy.weightedRoundRobin()`}</inlineCode>{` by default,
unless specified otherwise. Armeria currently provides the following `}<a parentName="p" {...{
        "href": "type://EndpointSelectionStrategy:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelectionStrategy.html"
      }}>{`type://EndpointSelectionStrategy`}</a>{`
implementations out-of-the-box:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`EndpointSelectionStrategy.weightedRoundRobin`}</inlineCode>{` for weighted round robin.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`EndpointSelectionStrategy.roundRobin`}</inlineCode>{` for round robin.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://StickyEndpointSelectionStrategy"
        }}>{`type://StickyEndpointSelectionStrategy`}</a>{` for pinning requests based on a criteria
such as a request parameter value.`}</li>
      <li parentName="ul">{`You can also implement your own `}<a parentName="li" {...{
          "href": "type://EndpointSelectionStrategy:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelectionStrategy.html"
        }}>{`type://EndpointSelectionStrategy`}</a>{`.`}</li>
    </ul>
    <p>{`An `}<a parentName="p" {...{
        "href": "type://EndpointSelectionStrategy:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelectionStrategy.html"
      }}>{`type://EndpointSelectionStrategy`}</a>{` can usually be specified as an input parameter or via a builder method
when you build an `}<a parentName="p" {...{
        "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
      }}>{`type://EndpointGroup`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.endpoint.EndpointSelectionStrategy;
import com.linecorp.armeria.client.endpoint.dns.DnsAddressEndpointGroup;

EndpointSelectionStrategy strategy = EndpointSelectionStrategy.roundRobin();

EndpointGroup group1 = EndpointGroup.of(
        strategy,
        Endpoint.of("127.0.0.1", 8080),
        Endpoint.of("127.0.0.1", 8081));

EndpointGroup group2 =
        DnsAddressEndpointGroup.builder("example.com")
                               .selectionStrategy(strategy)
                               .build();
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`You can create an `}<a parentName="p" {...{
          "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`type://Endpoint`}</a>{` with non-default weight using `}<a parentName="p" {...{
          "href": "type://Endpoint#withWeight(int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html#withWeight(int)"
        }}>{`type://Endpoint#withWeight(int)`}</a>{` method:`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-java"
        }}>{`// The default weight is 1000.
Endpoint endpointWithDefaultWeight = Endpoint.of("foo.com", 8080);
Endpoint endpointWithCustomWeight = endpointWithDefaultWeight.withWeight(1500);
assert endpointWithDefaultWeight.weight() == 1000;
assert endpointWithCustomWeight.weight() == 1500;
`}</code></pre>
    </Tip>
    <h2 {...{
      "id": "connecting-to-an-endpointgroup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#connecting-to-an-endpointgroup",
        "aria-label": "connecting to an endpointgroup permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connecting to an `}<inlineCode parentName="h2">{`EndpointGroup`}</inlineCode></h2>
    <p>{`Once an `}<a parentName="p" {...{
        "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
      }}>{`type://EndpointGroup`}</a>{` is created, you can specify it when creating a new client:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import static com.linecorp.armeria.common.SessionProtocol.HTTPS;

import com.linecorp.armeria.client.WebClient;
import com.linecorp.armeria.common.HttpResponse;
import com.linecorp.armeria.common.AggregatedHttpResponse;

// Create an HTTP client that sends requests to the searchEngineGroup.
WebClient client = WebClient.of(SessionProtocol.HTTPS, searchEngineGroup);

// Send a GET request to each search engine.
List<CompletableFuture<?>> futures = new ArrayList<>();
for (int i = 0; i < 3; i++) {
    final HttpResponse res = client.get("/");
    final CompletableFuture<AggregatedHttpResponse> f = res.aggregate();
    futures.add(f.thenRun(() -> {
        // And print the response.
        System.err.println(f.getNow(null));
    }));
}

// Wait until all GET requests are finished.
CompletableFuture.allOf(futures.toArray(new CompletableFuture[0])).join();
`}</code></pre>
    <h2 {...{
      "id": "cleaning-up-an-endpointgroup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#cleaning-up-an-endpointgroup",
        "aria-label": "cleaning up an endpointgroup permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Cleaning up an `}<inlineCode parentName="h2">{`EndpointGroup`}</inlineCode></h2>
    <p><a parentName="p" {...{
        "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
      }}>{`type://EndpointGroup`}</a>{` extends `}<inlineCode parentName="p">{`java.lang.AutoCloseable`}</inlineCode>{`, which means you need to call the `}<inlineCode parentName="p">{`close()`}</inlineCode>{`
method once you are done using it, usually when your application terminates:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Release all resources claimed by the group.
searchEngines.close();
`}</code></pre>
    <p><inlineCode parentName="p">{`close()`}</inlineCode>{` is a no-op for some `}<a parentName="p" {...{
        "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
      }}>{`type://EndpointGroup`}</a>{` implementations, but not all implementations are so,
especially those which updates the `}<a parentName="p" {...{
        "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
      }}>{`type://Endpoint`}</a>{` list dynamically, such as refreshing the list periodically.`}</p>
    <Tip mdxType="Tip">
      <p>{`An `}<a parentName="p" {...{
          "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
        }}>{`type://EndpointGroup`}</a>{`, whose `}<a parentName="p" {...{
          "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`typeplural://Endpoint`}</a>{` change even after it's instantiated and registered,
is called `}<em parentName="p">{`dynamic endpoint group`}</em>{`.`}</p>
    </Tip>
    <h2 {...{
      "id": "removing-unhealthy-endpoint-with-healthcheckedendpointgroup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#removing-unhealthy-endpoint-with-healthcheckedendpointgroup",
        "aria-label": "removing unhealthy endpoint with healthcheckedendpointgroup permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Removing unhealthy `}<inlineCode parentName="h2">{`Endpoint`}</inlineCode>{` with `}<inlineCode parentName="h2">{`HealthCheckedEndpointGroup`}</inlineCode></h2>
    <p><a parentName="p" {...{
        "href": "type://HealthCheckedEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/healthcheck/HealthCheckedEndpointGroup.html"
      }}>{`type://HealthCheckedEndpointGroup`}</a>{` decorates an existing `}<a parentName="p" {...{
        "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
      }}>{`type://EndpointGroup`}</a>{` to filter out the unhealthy
`}<a parentName="p" {...{
        "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
      }}>{`typeplural://Endpoint`}</a>{` from it so that a client has less chance of sending its requests to the unhealthy
`}<a parentName="p" {...{
        "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
      }}>{`typeplural://Endpoint`}</a>{`. It determines the healthiness by sending so called 'health check request' to each
`}<a parentName="p" {...{
        "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
      }}>{`type://Endpoint`}</a>{`, which is by default a simple `}<inlineCode parentName="p">{`HEAD`}</inlineCode>{` request to a certain path. If an `}<a parentName="p" {...{
        "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
      }}>{`type://Endpoint`}</a>{`
responds with non-200 status code or does not respond in time, it will be marked as unhealthy and thus
be removed from the list.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import static com.linecorp.armeria.common.SessionProtocol.HTTP;

import com.linecorp.armeria.client.WebClient;
import com.linecorp.armeria.client.endpoint.healthcheck.HealthCheckedEndpointGroup

// Create an EndpointGroup with 2 Endpoints.
EndpointGroup originalGroup = EndpointGroup.of(
    Endpoint.of("192.168.0.1", 80),
    Endpoint.of("192.168.0.2", 80));

// Decorate the EndpointGroup with HealthCheckedEndpointGroup
// that sends HTTP health check requests to '/internal/l7check' every 10 seconds.
HealthCheckedEndpointGroup healthCheckedGroup =
        HealthCheckedEndpointGroup.builder(originalGroup, "/internal/l7check")
                                  .protocol(SessionProtocol.HTTP)
                                  .retryInterval(Duration.ofSeconds(10))
                                  .build();

// Wait until the initial health check is finished.
healthCheckedGroup.whenReady().get();

// Specify healthCheckedGroup, not the originalGroup.
WebClient client = WebClient.builder(SessionProtocol.HTTP, healthCheckedGroup)
                            .build();
`}</code></pre>
    <Warning mdxType="Warning">
      <p>{`You must specify the wrapped `}<inlineCode parentName="p">{`healthCheckedGroup`}</inlineCode>{` when building a `}<a parentName="p" {...{
          "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
        }}>{`type://WebClient`}</a>{`, otherwise health
checking will not be enabled.`}</p>
    </Warning>
    <Tip mdxType="Tip">
      <p>{`You can decorate `}<em parentName="p">{`any`}</em>{` `}<a parentName="p" {...{
          "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
        }}>{`type://EndpointGroup`}</a>{` implementations with `}<a parentName="p" {...{
          "href": "type://HealthCheckedEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/healthcheck/HealthCheckedEndpointGroup.html"
        }}>{`type://HealthCheckedEndpointGroup`}</a>{`,
including what we will explain later in this page.`}</p>
    </Tip>
    <h2 {...{
      "id": "dns-based-service-discovery-with-dnsendpointgroup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dns-based-service-discovery-with-dnsendpointgroup",
        "aria-label": "dns based service discovery with dnsendpointgroup permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DNS-based service discovery with `}<inlineCode parentName="h2">{`DnsEndpointGroup`}</inlineCode></h2>
    <p>{`Armeria provides 3 DNS-based `}<a parentName="p" {...{
        "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
      }}>{`type://EndpointGroup`}</a>{` implementations:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DnsAddressEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/dns/DnsAddressEndpointGroup.html"
        }}>{`type://DnsAddressEndpointGroup`}</a>{` that retrieves the `}<a parentName="li" {...{
          "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`type://Endpoint`}</a>{` list from `}<inlineCode parentName="li">{`A`}</inlineCode>{` and `}<inlineCode parentName="li">{`AAAA`}</inlineCode>{` records`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DnsServiceEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/dns/DnsServiceEndpointGroup.html"
        }}>{`type://DnsServiceEndpointGroup`}</a>{` that retrieves the `}<a parentName="li" {...{
          "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`type://Endpoint`}</a>{` list from `}<inlineCode parentName="li">{`SRV`}</inlineCode>{` records`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DnsTextEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/dns/DnsTextEndpointGroup.html"
        }}>{`type://DnsTextEndpointGroup`}</a>{` that retrieves the `}<a parentName="li" {...{
          "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
        }}>{`type://Endpoint`}</a>{` list from `}<inlineCode parentName="li">{`TXT`}</inlineCode>{` records`}</li>
    </ul>
    <p>{`They refresh the `}<a parentName="p" {...{
        "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
      }}>{`type://Endpoint`}</a>{` list automatically, respecting TTL values, and retry when DNS queries fail.`}</p>
    <p><a parentName="p" {...{
        "href": "type://DnsAddressEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/dns/DnsAddressEndpointGroup.html"
      }}>{`type://DnsAddressEndpointGroup`}</a>{` is useful when accessing an external service with multiple public IP addresses:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`DnsAddressEndpointGroup group =
        DnsAddressEndpointGroup.builder("www.google.com")
                               // Refresh more often than every 10 seconds and
                               // less often than every 60 seconds even if DNS server asks otherwise.
                               .ttl(/* minTtl */ 10, /* maxTtl */ 60)
                               .build();

// Wait until the initial DNS queries are finished.
group.whenReady().get();
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "type://DnsServiceEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/dns/DnsServiceEndpointGroup.html"
      }}>{`type://DnsServiceEndpointGroup`}</a>{` is useful when accessing an internal service with
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/SRV_record"
      }}>{`SRV records`}</a>{`, which is often found in modern container
environments that leverage DNS for service discovery such as Kubernetes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.endpoint.dns.DnsServiceEndpointGroup;
import com.linecorp.armeria.client.retry.Backoff;

DnsServiceEndpointGroup group =
        DnsServiceEndpointGroup.builder("_http._tcp.example.com")
                               // Custom backoff strategy.
                               .backoff(Backoff.exponential(1000, 16000).withJitter(0.3))
                               .build();

// Wait until the initial DNS queries are finished.
group.whenReady().get();
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "type://DnsTextEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/dns/DnsTextEndpointGroup.html"
      }}>{`type://DnsTextEndpointGroup`}</a>{` is useful if you need to represent your `}<a parentName="p" {...{
        "href": "typeplural://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
      }}>{`typeplural://Endpoint`}</a>{` in a non-standard
form:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.endpoint.dns.DnsTextEndpointGroup;

// A mapping function must be specified.
DnsTextEndpointGroup group = DnsTextEndpointGroup.of("example.com", (byte[] text) -> {
    Endpoint e = /* Convert 'text' into an Endpoint here. */;
    return e
});

// Wait until the initial DNS queries are finished.
group.whenReady().get();
`}</code></pre>
    <h2 {...{
      "id": "zookeeper-based-service-discovery-with-zookeeperendpointgroup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#zookeeper-based-service-discovery-with-zookeeperendpointgroup",
        "aria-label": "zookeeper based service discovery with zookeeperendpointgroup permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ZooKeeper-based service discovery with `}<inlineCode parentName="h2">{`ZooKeeperEndpointGroup`}</inlineCode></h2>
    <p>{`First, You need the `}<inlineCode parentName="p">{`armeria-zookeeper3`}</inlineCode>{` dependency:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-zookeeper3'
    }]} mdxType="RequiredDependencies" />
    <p>{`Then, use `}<a parentName="p" {...{
        "href": "type://ZooKeeperEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/zookeeper/ZooKeeperEndpointGroup.html"
      }}>{`type://ZooKeeperEndpointGroup`}</a>{` and `}<a parentName="p" {...{
        "href": "type://ZooKeeperDiscoverySpec:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/zookeeper/ZooKeeperDiscoverySpec.html"
      }}>{`type://ZooKeeperDiscoverySpec`}</a>{` to retrieve the information
of servers:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.zookeeper.ZooKeeperDiscoverySpec;
import com.linecorp.armeria.client.zookeeper.ZooKeeperEndpointGroup;

String zkConnectionStr = "myZooKeeperHost:2181";
String znodePath = "/myProductionEndpoints";
ZooKeeperDiscoverySpec discoverySpec = ZooKeeperDiscoverySpec.curator(serviceName);
ZooKeeperEndpointGroup myEndpointGroup =
        ZooKeeperEndpointGroup.builder(zkConnectionStr, znodePath, discoverySpec)
                              .sessionTimeoutMillis(10000)
                              .build();
`}</code></pre>
    <p>{`The `}<a parentName="p" {...{
        "href": "type://ZooKeeperEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/zookeeper/ZooKeeperEndpointGroup.html"
      }}>{`type://ZooKeeperEndpointGroup`}</a>{` is used to fetch the binary representation of server information.
The `}<a parentName="p" {...{
        "href": "type://ZooKeeperDiscoverySpec:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/zookeeper/ZooKeeperDiscoverySpec.html"
      }}>{`type://ZooKeeperDiscoverySpec`}</a>{` converts the binary representation to an `}<a parentName="p" {...{
        "href": "type://Endpoint:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html"
      }}>{`type://Endpoint`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "type://ZooKeeperDiscoverySpec#curator(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/zookeeper/ZooKeeperDiscoverySpec.html#curator(java.lang.String)"
      }}>{`type://ZooKeeperDiscoverySpec#curator(String)`}</a>{` uses the format of
`}<a parentName="p" {...{
        "href": "https://curator.apache.org/curator-x-discovery/index.html"
      }}>{`Curator Service Discovery`}</a>{` which is compatible
with `}<a parentName="p" {...{
        "href": "https://cloud.spring.io/spring-cloud-zookeeper/reference/html/"
      }}>{`Spring Cloud Zookeeper`}</a>{`. If you
registered your server with `}<a parentName="p" {...{
        "href": "type://ZooKeeperRegistrationSpec#curator(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/zookeeper/ZooKeeperRegistrationSpec.html#curator(java.lang.String)"
      }}>{`type://ZooKeeperRegistrationSpec#curator(String)`}</a>{`, you must use
`}<a parentName="p" {...{
        "href": "type://ZooKeeperDiscoverySpec#curator(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/zookeeper/ZooKeeperDiscoverySpec.html#curator(java.lang.String)"
      }}>{`type://ZooKeeperDiscoverySpec#curator(String)`}</a>{`.`}</p>
    <p>{`You can use an existing
`}<a parentName="p" {...{
        "href": "https://curator.apache.org/apidocs/org/apache/curator/framework/CuratorFramework.html"
      }}>{`CuratorFramework`}</a>{`
instance instead of the ZooKeeper connection string at this time as well.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import org.apache.curator.framework.CuratorFramework;

CuratorFramework client = ...
String znodePath = ...
ZooKeeperDiscoverySpec discoverySpec = ...
ZooKeeperEndpointGroup myEndpointGroup =
        ZooKeeperEndpointGroup.builder(client, znodePath, discoverySpec)
                              .build();
`}</code></pre>
    <p>{`For more information, please refer to the API documentation of the
`}<a parentName="p" {...{
        "href": "https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/client/package-summary.html"
      }}>{`com.linecorp.armeria.client.zookeeper`}</a>{` package.`}</p>
    <Tip mdxType="Tip">
      <p>{`See `}<a parentName="p" {...{
          "href": "/docs/server-service-registration#zookeeper-based-service-registration-with-zookeeperupdatinglistener"
        }}>{`ZooKeeper service registration`}</a>{`.`}</p>
    </Tip>
    <h2 {...{
      "id": "eureka-based-service-discovery-with-eurekaendpointgroup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#eureka-based-service-discovery-with-eurekaendpointgroup",
        "aria-label": "eureka based service discovery with eurekaendpointgroup permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Eureka-based service discovery with `}<inlineCode parentName="h2">{`EurekaEndpointGroup`}</inlineCode></h2>
    <p>{`First, You need the `}<inlineCode parentName="p">{`armeria-eureka`}</inlineCode>{` dependency:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-eureka'
    }]} mdxType="RequiredDependencies" />
    <p>{`Then, use `}<a parentName="p" {...{
        "href": "type://EurekaEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/eureka/EurekaEndpointGroup.html"
      }}>{`type://EurekaEndpointGroup`}</a>{` to retrieve the information of servers:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.eureka.EurekaEndpointGroup;

EurekaEndpointGroup eurekaEndpointGroup =
        EurekaEndpointGroup.of("https://eureka.com:8001/eureka/v2");
`}</code></pre>
    <p>{`If you want to retrieve the information of certain regions or a service,
use `}<a parentName="p" {...{
        "href": "type://EurekaEndpointGroupBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/eureka/EurekaEndpointGroupBuilder.html"
      }}>{`type://EurekaEndpointGroupBuilder`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.eureka.EurekaEndpointGroupBuilder;

EurekaEndpointGroupBuilder builder =
        EurekaEndpointGroup.builder("https://eureka.com:8001/eureka/v2");
                           .regions("aws")
                           .appName("myApplication");
EurekaEndpointGroup eurekaEndpointGroup = builder.build();
`}</code></pre>
    <p>{`For more information, please refer to the API documentation of the
`}<a parentName="p" {...{
        "href": "https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/eureka/package-summary.html"
      }}>{`com.linecorp.armeria.client.eureka`}</a>{` package.`}</p>
    <Tip mdxType="Tip">
      <p>{`See `}<a parentName="p" {...{
          "href": "/docs/server-service-registration#eureka-based-service-registration-with-eurekaupdatinglistener"
        }}>{`Eureka service registration`}</a>{`.`}</p>
    </Tip>
    <h2 {...{
      "id": "consul-based-service-discovery-with-consulendpointgroup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#consul-based-service-discovery-with-consulendpointgroup",
        "aria-label": "consul based service discovery with consulendpointgroup permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://www.consul.io"
      }}>{`Consul`}</a>{`-based service discovery with `}<inlineCode parentName="h2">{`ConsulEndpointGroup`}</inlineCode></h2>
    <p>{`First, You need the `}<inlineCode parentName="p">{`armeria-consul`}</inlineCode>{` dependency:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-consul'
    }]} mdxType="RequiredDependencies" />
    <p>{`Then, use `}<a parentName="p" {...{
        "href": "type://ConsulEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/consul/ConsulEndpointGroup.html"
      }}>{`type://ConsulEndpointGroup`}</a>{` to retrieve the information of servers:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.consul.ConsulEndpointGroup;

ConsulEndpointGroup consulEndpointGroup =
        ConsulEndpointGroup.of("http://my-consul.com:8500", "my-service");
`}</code></pre>
    <p>{`If you want to get the information of certain `}<a parentName="p" {...{
        "href": "https://www.consul.io/api-docs/catalog#dc-2"
      }}>{`datacenter`}</a>{` or
`}<a parentName="p" {...{
        "href": "https://www.consul.io/api-docs/features/filtering"
      }}>{`filter`}</a>{` endpoints, use `}<a parentName="p" {...{
        "href": "type://ConsulEndpointGroupBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/consul/ConsulEndpointGroupBuilder.html"
      }}>{`type://ConsulEndpointGroupBuilder`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.client.consul.ConsulEndpointGroupBuilder;

ConsulEndpointGroupBuilder builder =
        ConsulEndpointGroup.builder("http://my-consul.com:8500", "my-service");
                           .datacenter("ds1")
                           .filter("ServicePort == 8080")
                           .build();
ConsulEndpointGroup consulEndpointGroup = builder.build();
`}</code></pre>
    <p>{`For more information, please refer to the API documentation of the
`}<a parentName="p" {...{
        "href": "https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/consul/package-summary.html"
      }}>{`com.linecorp.armeria.client.consul`}</a>{` package.`}</p>
    <Tip mdxType="Tip">
      <p>{`See `}<a parentName="p" {...{
          "href": "/docs/server-service-registration#consul-based-service-registration-with-consulupdatinglistener"
        }}>{`Consul service registration`}</a>{`.`}</p>
    </Tip>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      